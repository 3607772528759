@import 'cells-energy';
html,
body {
  width: 100%;
  height: 100%;
}

.Site {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.Site-content {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
}

#tooltip-portal {
  // HACK: appear above $zindex-modal (_variables.scss in bootstrap)
  position: relative;
  z-index: 1051;
}
