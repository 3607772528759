@import 'cells-energy';
.ConsumerContract {
  h3 {
    text-align: center;
  }

  .progress-tracker-spacing {
    height: 50px;
  }

  .contract-fields {
    text-align: left;

    .shrink {
      width: initial;
    }
  }

  label {
    margin: 0;

    span {
      width: 300px;
    }
  }

  input {
    width: 300px;

    &.full-length {
      width: 100%;
    }

    &.short {
      width: 80px;
    }

    &.same-bank-data {
      width: initial;
    }
  }

  .small-then-big {
    display: flex;

    .form-group:nth-child(1) {
      margin-right: 30px;
    }

    .form-group:nth-child(2) {
      flex-grow: 1;
    }
  }

  .big-then-small {
    display: flex;

    .form-group:nth-child(1) {
      flex-grow: 1;
    }

    .form-group:nth-child(2) {
      margin-left: 30px;
    }
  }

  .iban-bic {
    margin-bottom: 1rem;
  }

  .bank-sepa {
    padding-left: 10px;

    span {
      width: initial;
    }
  }

  .vat-info {
    font-size: x-small;
    color: $cells-dark-grey;
    line-height: initial;
    margin-top: 8px;
  }

  .confirmation-page {
    h4 {
      margin-top: 20px;
    }

    .summary {
      margin-bottom: 20px;

      .my-mix-header {
        margin-top: 20px;
      }

      // todo: extract Producer into its own component and delete this redundant CSS
      // (unless we decide to change the look of this page)
      .producers {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        text-align: center;

        .producer {
          display: flex;
          flex-flow: column;
          width: 150px;
          margin: 10px;
          color: $cells-text-colour;
          font-weight: initial;

          img {
            width: 150px;
            height: 100px;
            object-fit: cover;
          }

          .plant-name {
            padding: 3px 5px;
            background-color: $cells-grey-background;
          }

          .owner-name {
            width: 100%;
            padding: 8px 5px;
            background-color: $cells-grey-background;
            font-size: small;
            line-height: initial;
          }

          span {
            display: inline-block;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          @media only screen and (max-device-width: 328px) {
            width: 70%;

            img {
              width: 100%;
              height: 150px;
            }
          }
        }
      }
    }

    hr {
      margin-bottom: 50px;
    }

    .email-phone {
      display: flex;
    }

    .sign-contract-button-container {
      display: flex;
      flex-flow: column;
      align-items: center;
      margin-top: 50px;

      & :first-child {
        margin-bottom: 3px;
      }
    }
  }

  .overview {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 70%;

    display: flex;
    align-items: center;
    pointer-events: none;

    .inner {
      border: 1px solid $cells-grey-elements;
      padding: 10px 0;
      background-color: $cells-white;
      pointer-events: initial;

      .info {
        padding-top: 10px;
      }
    }

    @media only screen and (max-device-width: $md-breakpoint) {
      display: none;
    }
  }

  .yearly-costs {
    font-weight: bold;
    padding: 5px;
    font-size: larger;
    line-height: initial;
  }

  .info {
    color: $cells-dark-grey;
    padding: 6px 10px;
    line-height: initial;

    ul {
      margin: 0;
      padding: 0;
    }

    li {
      list-style-type: none;
    }
  }

  .errors {
    margin-top: 20px;

    ul {
      margin-bottom: 0;
    }
  }
}
