@import 'cells-energy';
.GetAQuote {
  display: flex;
  flex-flow: column;
  align-items: center;

  input {
    -moz-appearance: textfield;
    width: 250px;
    text-align: center;
    margin-bottom: 2px;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  button {
    width: 250px;
  }
}

.coming-soon {
  width: 400px;
  align-self: center;
  color: white;
  background-color: rgba(159, 0, 86, 0.65);
}
