@import 'cells-energy';
.back-button {
  margin: 50px 0 0 30px;
  width: -moz-fit-content;
  width: fit-content;

  @media only screen and (max-device-width: $sm-breakpoint) {
    margin: 50px 0 0 0;
  }

  svg {
    font-size: 25px;
    margin-right: 10px;
  }

  a,
  span {
    display: flex;
    align-items: center;
    color: $cells-text-colour;
    text-decoration: none;
    font-weight: initial;
    cursor: pointer;
  }

  a:hover {
    text-decoration: none;
  }
}
