@import 'cells-energy';
.progress-tracker {
  display: flex;
  flex-flow: row;

  .step {
    display: flex;
    flex-flow: row;

    .step-name-number {
      display: flex;
      flex-flow: column;
      align-items: center;
      width: 100px;

      .step-number {
        color: $cells-grey-elements;
        font-size: 30px;
        width: 64px;
        height: 71px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-image: url("../images/cells/cell-grey.svg");
        background-repeat: no-repeat;
      }

      .step-number-image img {
        width: 64px;
        opacity: 0.7;
      }

      .step-name {
        color: $cells-grey-elements;
        white-space: nowrap;
      }
    }
  }

  .current {
    .step-name-number {
      .step-number {
        color: $cells-text-colour;
      }

      .step-number-image img {
        width: 64px;
        opacity: unset;
      }

      .step-name {
        color: $cells-text-colour;
      }
    }
  }

  .done {
    .step-name-number {
      cursor: pointer;
    }
  }

  .line-container {
    display: flex;
    align-items: center;
    margin: 0 5 0 10px;

    .line {
      border: 1px solid $cells-grey-elements;
      border-radius: 10px;
      width: 70px;
      margin-bottom: 18px;
    }
  }
}

@media only screen and (max-device-width: 576px) {
  .progress-tracker {
    flex-flow: column;
    align-items: flex-start;
    margin-left: 30px;

    .step {
      .step-name-number {
        flex-flow: row;
        width: initial;
        margin-bottom: 7px;

        .step-number {
          margin-right: 15px;
        }
      }
    }

    .line-container {
      display: none;
    }
  }
}
