@import 'cells-energy';
.HowMuchPowerDoYouNeed {
  .number-of-people {
    display: flex;
    flex-flow: column;
    align-items: center;
    margin-bottom: 20px;

    .people {
      display: flex;
      flex-flow: row;
      justify-content: center;
      width: 425px;
      margin-bottom: 10px;
    }

    .people {
      .person {
        font-size: 100px;
        margin: 0 20px;

        @media only screen and (max-device-width: $sm-breakpoint) {
          font-size: 50px;
        }
      }
    }

    .text {
      display: flex;
      flex-flow: column;
      margin-bottom: 10px;

      .number {
        font-size: 20px;
        font-weight: 500;
      }

      .yearly-energy {
        font-size: 12px;
      }
    }

    .controls {
      display: flex;
      flex-flow: row;
      justify-content: center;
      align-items: center;

      .disabled {
        opacity: 0.4;
      }

      img {
        margin: 0 10px;
        cursor: pointer;
      }
    }
  }

  .annual-consumption {
    .annual-consumption-col {
      display: flex;
      flex-flow: column;

      ul {
        width: 90%;
        align-self: center;
        text-align: left;
      }
    }

    &.with-partner {
      .annual-consumption-col,
      .partner-col {
        margin-top: 50px;
      }

      .annual-consumption-col {
        text-align: left;

        ul {
          align-self: flex-start;
          text-align: left;
        }
      }

      .partner-image {
        margin-bottom: 30px;
      }
    }

    .form-group {
      display: flex;
      justify-content: center;
      align-items: center;

      input {
        width: 150px;
        margin-right: 10px;
        text-align: center;
      }

      label {
        margin-bottom: 0;
      }
    }

    button {
      margin-right: 10px;
    }

    &.rlm-choice {
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;

      .rlm-button,
      .no-rlm-button {
        width: 230px;
      }

      .no-rlm-button {
        margin-bottom: 10px;
      }
    }
  }

  footer {
    display: flex;
    flex-flow: column;
    align-items: center;
  }
}
