@import 'cells-energy';
.ExtendedMixInfo {
  .info-body {
    padding: 10px;
  }

  .overview {
    table {
      line-height: 24px;

      .field-name {
        font-weight: bold;
        padding-right: 20px;
      }
    }
  }

  .custom-margins {
    text-align: left;

    .CollapsibleInformation {
      header {
        display: none;
      }

      .collapse {
        display: initial;

        .body {
          border: none;
        }
      }
    }
  }

  .calculation {
    table {
      width: 100%;
      line-height: 28px;

      .before-total {
        border-bottom: 1px solid black;
      }

      .total {
        border-bottom: 2px solid black;
      }

      .final {
        background-color: $cells-grey-background;
      }
    }
  }

  .vat-info {
    font-size: x-small;
    color: $cells-dark-grey;
    line-height: initial;
    margin-top: 8px;
  }

  .producers {
    display: flex;
    flex-flow: column;
    align-items: center;

    .producer {
      display: flex;
      flex-flow: row;
      width: calc(100% - 20px);
      margin: 10px;
      background-clip: border-box;
      border: 1px solid rgba(0, 0, 0, 0.125);
      border-radius: 0.25rem;

      img {
        width: 180px;
        height: 150px;
        object-fit: cover;
        border-top-left-radius: calc(0.25rem - 1px);
        border-bottom-left-radius: calc(0.25rem - 1px);
      }

      a {
        color: $cells-text-colour;
        font-weight: inherit;
        font-size: inherit;
        line-height: inherit;

        &:hover {
          text-decoration: none;
        }
      }

      .content {
        display: flex;
        flex-flow: column;
        text-align: left;
        padding-left: 15px;

        .plant-name {
          margin-top: 10px;
          font-size: 15px;
        }

        .name-city {
          font-size: 13px;
        }

        .info {
          font-size: 11px;
          color: $cells-dark-grey;
          padding: 10px 10px;
          line-height: 18px;

          li {
            list-style-type: none;
          }
        }
      }

      .view-profile-button {
        text-align: right;
        align-self: center;
        margin-left: auto;
        padding-right: 20px;
        display: none;

        .btn {
          min-width: 171px;
        }
      }

      @media only screen and (max-device-width: $mobile-break-point) {
        flex-flow: column;
        align-items: center;
        width: 200px;

        img {
          width: 100%;
          height: 150px;
          border-top-right-radius: calc(0.25rem - 1px);
          border-bottom-left-radius: initial;
        }

        .content {
          padding: 0 10px;
          text-align: center;
          flex-grow: 1;
        }

        .view-profile-button {
          margin: 0;
          padding: 0;
          padding-bottom: 10px;
          text-align: center;
        }
      }
    }
  }
}
