@import 'cells-energy';
.LandingConsumer {
  margin: 0;
  flex: 1 0 auto;

  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-bottom: 20px;
    min-height: $landing-page-header-min-height;

    .background {
      position: absolute;
      width: 100%;
      height: 100%;
      overflow: hidden;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      & > div:first-child {
        height: 100%;

        .carousel {
          height: 100%;

          .slider-wrapper {
            height: 100%;

            .slider {
              height: 100%;

              .slide {
                height: 100%;

                .image-outer {
                  height: 100%;
                }
              }
            }
          }

          .control-dots {
            padding: 0;
          }
        }
      }

      img {
        width: 100%;
        height: 102%;
        object-fit: cover;
        object-position: 51% 63%;
      }

      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #50505014;
        pointer-events: none;
      }
    }

    .text {
      position: relative;
      width: initial;
      margin: auto;
      padding: 20px 100px;

      h1,
      h3 {
        color: white;
      }

      .row {
        flex-flow: column;
        justify-content: center;
      }
    }

    @media only screen and (max-device-width: $sm-breakpoint) {
      h1 {
        font-size: 36px;
      }

      h3 {
        font-size: 18px;
        text-transform: initial;
      }
    }
  }

  .title {
    margin-bottom: 50px;
    font-size: 60px;
    letter-spacing: 2px;
    text-transform: initial;
  }

  .subtitle {
    margin-bottom: 30px;
    font-size: 30px;
    text-transform: initial;
  }

  .GetAQuote {
    justify-content: center;

    input,
    button {
      width: 200px;
    }

    input {
      margin-bottom: 3px;
    }

    button {
      height: calc(2.25rem + 2px);
    }
  }

  .information {
    text-align: center;
    padding: 40px 0;

    h2 {
      padding: 0 15px;
      color: $cells-blue;
      font-size: 56px;
      text-transform: initial;
    }

    .TextWithIcon {
      header {
        h3 {
          color: $cells-blue;
          font-size: 42px;
          margin-bottom: 18px;
        }
      }

      p,
      a {
        font-size: $landing-page-information-font-size;
        line-height: $landing-page-information-line-height;
      }
    }

    .how-it-works {
      p {
        font-size: $landing-page-information-font-size;
        line-height: $landing-page-information-line-height;
      }
    }

    .map {
      h2 {
        margin-bottom: 40px;
      }

      .map-container {
        height: 50vh;
      }

      .loader {
        height: 50vh;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .join-community-text {
        margin-top: 0.5rem;
      }
    }

    .texts-and-icons {
      justify-content: center;

      .col {
        flex-grow: 0;
      }
    }

    .variable-pricing-image {
      width: 65%;
    }

    @media only screen and (max-device-width: $sm-breakpoint) {
      padding: 20px 0;

      h2 {
        font-size: 20px;
      }

      h3 {
        font-size: 18px;
      }

      .TextWithIcon {
        width: initial;
        margin-top: 10px;
        padding: 0 12px;

        .icons {
          display: none;
        }

        header {
          h3 {
            color: $cells-text-colour;
            font-size: 18px;
            font-weight: bold;
          }
        }

        main {
          text-align: center;

          p,
          a {
            line-height: initial;
            font-size: initial;
          }
        }
      }

      .variable-pricing-image {
        width: 90%;
      }
    }
  }

  .section-odd {
    height: fit-content;
    background-color: $cells-grey-background;
  }

  @media only screen and (max-device-width: $sm-breakpoint) {
    .section-odd {
      background-color: initial;
    }

    .section-even {
      background-color: $cells-grey-background;
    }
  }

  .information-footer {
    padding: 30px 0;
    color: $cells-white;
    font-weight: bold;
    background-image: linear-gradient(
      45deg,
      $cells-light-blue,
      $cells-dark-blue
    );

    a {
      color: $cells-text-colour;
    }

    .no-margin p {
      margin: 0;
    }
  }
}
