@import 'cells-energy';
.PaymentDetailsForm {
  .contract-fields {
    h4 {
      font-size: 1rem;
    }

    .payment-method {
      .intro-text {
        margin-right: 10px;
      }
    }
  }
}
