@import 'cells-energy';
.InfoIcon {
  .icon {
    width: 10px;
    height: 10px;
    padding: 3px;
    border: 1px solid black;
    border-radius: 25px;
    cursor: help;
  }
}

.info-icon-tooltip {
  max-width: 100vw;
}
