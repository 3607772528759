@import 'cells-energy';
.PasswordReset {
  input {
    margin-bottom: 5px;
    max-width: 300px;
  }

  button {
    margin-top: 10px;
  }
}
