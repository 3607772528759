@import 'cells-energy';
.WhyDoYouNeedPower {
  .user-types {
    display: flex;
    flex-flow: row;
    justify-content: center;
    flex-wrap: wrap;

    .user-type {
      display: flex;
      flex-flow: column;
      align-items: center;

      width: 250px;

      cursor: pointer;

      img {
        width: 200px;
        border-radius: 10px;
      }
    }
  }

  @media only screen and (max-device-width: $sm-breakpoint) {
    .user-types {
      .user-type {
        width: 50%;

        img {
          width: 95%;
        }
      }
    }
  }
}
