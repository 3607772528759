@import 'cells-energy';
.CollapsibleInformation {
  header {
    position: relative;
    cursor: pointer;
    border-top: 1px solid $cells-grey-elements;
    border-bottom: 1px solid $cells-grey-elements;
    padding: 4px;
    font-weight: 500;

    &:hover {
      background-color: $cells-grey-background;
    }

    &.show {
      border-bottom: none;
    }

    .title {
      display: inline-block;
      padding: 0 30px;
    }

    .icon {
      display: flex;
      flex-flow: column;
      justify-content: center;
      position: absolute;
      right: 30px;
      top: 0;
      height: 100%;
      padding-bottom: 1px;
    }
  }

  .body {
    border-bottom: 1px solid $cells-grey-elements;
    padding: 5px;
  }
}
