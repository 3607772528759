@import 'cells-energy';
.Birthdate {
  .fields {
    display: flex;
    flex-flow: row;

    .form-group {
      margin-right: 15px;
    }

    label {
      font-size: smaller;
    }

    input {
      text-align: center;
    }

    .day,
    .month {
      input {
        width: 45px;
      }
    }

    .year {
      input {
        width: 64px;
      }
    }
  }
}
