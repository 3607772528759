@import 'cells-energy';
.StayInContact {
  display: flex;
  flex-flow: column;
  align-items: center;

  input {
    width: 270px;
  }

  button {
    margin-top: 8px;
  }

  .alert {
    margin-top: 20px;
  }

  .error {
    p {
      margin-bottom: 0;
    }
  }
}
