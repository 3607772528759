$cells-blue: #0082ca;
$cells-dark-grey: #676767;
$cells-grey-elements: #dadada;
$cells-grey-background: #f5f5f5;
$cells-white: #ffffff;
$cells-black: #000000;
$cells-text-colour: #444444;
$cells-light-blue: #3db9ef;
$cells-dark-blue: #0283c9;

$producer-colour-1: #008ffb;
$producer-colour-2: #00e396;
$producer-colour-3: #feb019;
$producer-colour-4: #ff4560;
$producer-colour-5: #775dd0;

/* klaro cookie consent variables */
:root {
  --green1: #0082ca;
  --green2: #3db9ef;
  --blue1: #3db9ef;
  --light1: #444444;
  --dark2: #676767;
}

$lg-breakpoint: 1199px;
$md-breakpoint: 991px;
$sm-breakpoint: 767px;
$mobile-break-point: 925px;

$font-family-text: "Roboto", sans-serif;
$font-size-text: 16px;
$line-height-text: 28px;

$font-family-header: "Roboto Condensed", sans-serif;
$h1-font-size: 48px;
$h2-font-size: 34px;
$h3-font-size: 24px;

$link-color: $cells-blue;

$app-background-image: url("./images/background/cells_energy_hintergrund.svg");

$logo-width: 218px;
$logo-height: 60px;

$landing-page-header-min-height: calc(100vh - 180px);
$landing-page-information-font-size: 22px;
$landing-page-information-line-height: 32px;
