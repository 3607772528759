@import 'cells-energy';
.TextWithIcon {
  display: flex;
  flex-flow: row;
  justify-content: space-around;
  align-items: center;
  margin-top: 30px;
  text-align: center;

  &.right-to-left {
    flex-flow: row-reverse;
  }

  .icons {
    display: flex;
    flex-flow: row;
    align-items: flex-end;
    margin-bottom: 30px;

    &.right-to-left {
      flex-flow: row-reverse;
    }

    .icon-outer {
      position: relative;
      width: 250px;
      height: 250px;

      .icon {
        width: 100%;
        height: 100%;
        object-fit: cover;
        -webkit-mask: url("../images/cells/cell-blue.svg") center no-repeat;
        mask: url("../images/cells/cell-blue.svg") center no-repeat;
      }

      .icon-border {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;

        .cls-1 {
          fill: #fff0;
          stroke: $cells-blue;
          stroke-miterlimit: 10;
          stroke-width: 5px;
        }
      }
    }

    .check-symbol {
      width: 110px;
      height: 120px;
      background-image: url("../images/icons/icon-check-blue.svg");
      background-repeat: no-repeat;
    }
  }

  main {
    max-width: 600px;
    text-align: left;

    header {
      h3 {
        text-transform: initial;
      }

      margin-bottom: 10px;
    }
  }
}
