@import 'cells-energy';
.LandingProducer {
  .page-header {
    margin-bottom: 100px;
  }

  .cta {
    display: flex;
    justify-content: center;

    @media only screen and (max-device-width: 576px) {
      margin-top: 30px;
    }

    .link {
      .button {
        position: relative;
        width: 196px;
        max-width: 196px;
        margin-bottom: 10px;

        .image {
          width: inherit;
          height: 225px;
          background-image: url("../../images/icons/icon-solar.svg");
          background-repeat: no-repeat;
        }

        .symbol {
          position: absolute;
          right: 17px;
          bottom: 0;
          width: 55px;
          height: 60px;
          background-repeat: no-repeat;
        }

        @media only screen and (max-device-width: 576px) {
          width: 150px;
          max-width: 150px;

          .symbol {
            bottom: 51px;
            width: 42px;
            height: 46px;
          }
        }
      }

      h3 {
        width: 100%;
      }
    }

    .link:hover {
      text-decoration: none;
    }

    .anlagen-check-outer {
      display: flex;
      flex-flow: column;
      align-items: center;
      width: 50%;

      .anlagen-check-link {
        .anlagen-check-button {
          .anlagen-check-image {
            background-image: url("../../images/icons/icon-solar.svg");
          }

          .anlagen-check-check {
            background-image: url("../../images/icons/icon-check-in.svg");
          }
        }
      }

      .subtext {
        max-width: 450px;
        background-color: $cells-grey-background;
        padding: 5px;
      }
    }

    .jetzt-anmelden-link {
      display: flex;
      flex-flow: column;
      align-items: center;
      width: 50%;

      .jetzt-anmelden-button {
        .jetzt-anmelden-image {
          background-image: url("../../images/icons/icon-solar.svg");
        }

        .jetzt-anmelden-plus {
          background-image: url("../../images/icons/icon-plus.svg");
        }
      }
    }
  }

  .arrow {
    margin-top: 120px;
    margin-bottom: 75px;

    @media only screen and (max-device-width: 576px) {
      margin-top: 10px;
    }
  }

  main {
    text-align: left;

    .row {
      padding-top: 40px;
      padding-bottom: 40px;
    }

    .odd {
      background-color: $cells-grey-background;
    }

    .photos-column {
      position: relative;
      width: 300px;

      .double-cell {
        width: 380px;
      }

      .double-cell-horizontal,
      &.triple-cell-horizontal {
        display: none;
      }

      @media only screen and (max-device-width: $md-breakpoint) {
        width: initial;
        display: flex;
        justify-content: space-around;
        margin-bottom: 30px;

        .double-cell {
          display: none;
        }

        &.triple-cell {
          display: none;
        }

        .single-cell {
          margin-right: auto;
        }

        .double-cell-horizontal {
          display: initial;
          width: 45%;
          object-fit: contain;
        }

        &.triple-cell-horizontal {
          display: initial;

          img {
            width: 33%;
          }

          @media only screen and (max-device-width: 575px) {
            display: none;
          }

          .col {
            padding: 5px;
          }
        }
      }

      .single-cell {
        width: 295px;
        height: 340px;
      }
    }

    .text-column {
      h2 {
        color: $cells-blue;
        text-transform: initial;
      }

      .dmsp-text {
        margin-bottom: 75px;
      }
    }
  }

  footer {
    padding-top: 50px;

    h2 {
      margin-bottom: 50px;
      text-transform: initial;
    }

    .potential-check-bottom {
      margin-bottom: 10px;
    }
  }
}
