@import 'cells-energy';
.AddToMixWidget {
  width: 100%;
  padding: 30px 20px;
  box-shadow: 1px 1px 4px black;
  background-color: white;

  h2 {
    margin-bottom: 30px;
    font-size: 20px;
    text-transform: initial;
  }

  .producers {
    margin-bottom: 20px;

    .producer {
      display: flex;
      flex-flow: row;
      align-items: center;
      margin-bottom: 8px;

      .plant-image {
        width: 50px;
        height: 50px;
        margin-right: 10px;
        object-fit: cover;
        box-shadow: 1px 1px 4px black;
      }

      .plant-name {
        display: inline-block;
        flex-grow: 1;
        text-align: left;
      }

      .remove-button {
        cursor: pointer;
      }
    }
  }

  .buttons {
    display: flex;
    flex-flow: column;
    align-items: center;

    .btn-primary {
      margin-bottom: 5px;
    }
  }
}
