@import 'cells-energy';
.continue-button {
  width: 64px;
  height: 71px;
  background-image: url("../images/icons/icon-continue.svg");
  background-repeat: no-repeat;
  cursor: pointer;
}

.continue-button-disabled {
  cursor: initial;
  opacity: 0.3;
}
