@import 'cells-energy';
.MultiplierMix {
  h1,
  h2 {
    text-transform: initial;
  }

  .header-image-outer {
    position: relative;
    width: 500px;
    height: 333px;
    margin-bottom: 50px;

    .header-image {
      width: 100%;
      height: 100%;
      box-shadow: 1px 1px 4px black;
      object-fit: cover;
    }

    .logo-outer {
      position: absolute;
      right: 0;
      top: 0;

      width: calc(128px * 2 / 3);
      height: calc(145px * 2 / 3);
      padding: 5px 0 0 5px;

      .watermark-background {
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        background-color: #ffffff9e;
        border-radius: 0 0 0 10px;
      }

      .logo {
        position: absolute;
        top: 5px;
        left: 5px;

        width: calc(100% - 5px);
        height: calc(100% - 5px);
      }
    }

    .regio-cells-logo-outer {
      top: unset;
      bottom: 0;

      .watermark-background {
        border-radius: 10px 0 0 0;
      }
    }
  }

  .header {
    margin-bottom: 30px;
  }

  .description-col {
    max-width: 500px;
    margin: auto;
    margin-bottom: 20px;
    text-align: center;
  }

  .center-text {
    text-align: center;
  }

  .producers {
    display: flex;
    flex-direction: row;
    justify-content: center;

    @media only screen and (max-device-width: $md-breakpoint) {
      flex-wrap: wrap;
      padding: 0 10px;
    }

    .producer {
      display: flex;
      flex-flow: column;
      width: 150px;
      margin: 10px;
      color: $cells-text-colour;
      font-weight: initial;
      cursor: pointer;

      img {
        width: 150px;
        height: 100px;
        object-fit: cover;
        box-shadow: 1px 1px 4px black;
      }

      .plant-name {
        padding-top: 10px;
        background-color: initial;
      }

      .owner-name {
        display: none;
      }

      span {
        display: inline-block;
        width: 100%;
        font-size: 12px;
        line-height: initial;
      }
    }
  }

  .ShowWithMixesWidget {
    max-width: 400px;
    padding: 30px 50px;
    background-color: initial;
    box-shadow: none;

    .GetAQuote input {
      width: 100%;
    }

    .submit-button {
      display: flex;
      align-items: center;
    }
  }

  .own-mix-button {
    margin-top: 30px;
  }
}
